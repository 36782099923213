<template>
    <div class="ctn-box">
      <div id="first-page" class="section">
         <div id="saintOuen">
            <section id="saintOuen-1" class="page-section saintOuen-1" @wheel="beginAnimationZoom">
               <VideoSaintOuen/>
               <h1 class="title-h1 txt-right txt-right" :class="{'anim-slide-right-blur':AnimtxtBlur}">
                  <span>{{$t('titleSaintouen')['0']}}</span>
                  <span>{{$t('titleSaintouen')['1']}}</span>
                  <span>{{$t('titleSaintouen')['2']}}</span>
               </h1>
               <div class="scroll-right">
                  <a href="#" class="btn btn-white btn-scroll" v-on:click="scrollToNext">  
                     <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                     </svg>
                     <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                     </svg>
                  </a>
               </div>
               <div class="bg-mask"></div>
               </section>

               <section id="saintOuen-2" class="page-section saintOuen-2">
                  <div class="h-100 wallpaper-zoom-inactive">
                     <div class="loc-contain" :class="{'wallpaper-zoom': showZoom}">
                        <figure class="desk-img"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/mob-saintOuen-loc.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/mob-saintOuen-loc.jpg"' alt="bg-saintOuen-2"></figure>
                        <div class="loc-bloc">
                           <div class="loc-bloc-contain">
                              <span class="loc-bloc-circle"></span> 
                              <span class="loc-bloc-txt" :class="{'anim-slide-right-blur':AnimLoc}">RHAPSODY</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
      
               <section id="suivre-ligne" class="page-section saintOuen-3">
                  <div class="map-ctn">
                     <h2 class="title-h2">{{$t('titleSuivrelaligne')['0']}} <br class="d-block">{{$t('titleSuivrelaligne')['1']}}</h2>
                     <figure class="map-harmony">
                        <img class="tablet-img" :src='this.$pathprod+"images/saint-ouen/desk-groupe-01.svg"' alt="bg-saintOuen-3">
                        <img class="mob-img loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/mob-groupe-01.svg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/mob-groupe-01.svg"' alt="bg-saintOuen-3">
                     </figure>
                  </div>
               </section>

               <section id="saintOuen-4" class="page-section saintOuen-4">
                  <figure class="map-harmony">
                     <img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/batiment-rhapsody.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/batiment-rhapsody-retina.jpg 2x"' alt="batiment-harmony">
                  </figure>
               </section>

               <SaintOuen5/>
               <SaintOuen6/>
               <SaintOuen7/>

               <section id="footer-sct" class="page-section">
                  <div class="footer-sct-bloc anim-number">
                        <div class="sct-left custom-sct">
                           <div class="sct-left-txt">
                              <h4 class="title-h4">{{$t('dataKeyChiffreTertiaire')['5']}}</h4>
                              <p><strong>{{$t('dataKeyChiffreTertiaire')['6']}}</strong> {{$t('dataKeyChiffreTertiaire')['7']}}</p>
                           </div>
                        </div>

                        <div class="sct-rightCustom">
                           <div>
                              <div class="key-number-item mb-30">
                                 <p class="key-number"><span class="number-to-animate">
                                 <number
                                    animationPaused
                                    ref="number1"
                                    :from="100"
                                    :to="300000"
                                    :format="theFormat"
                                    :duration="3"
                                    easing="Power1.easeOut"
                                 />
                                 </span>m<span class="m2">2</span></p>
                                 <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['8']}}</p>
                              </div>
                              <div class="key-number-item mb-30">
                                 <p class="key-number"><span class="number-to-animate">
                                    <number
                                       animationPaused
                                       ref="number2"
                                       :from="100"
                                       :to="67000"
                                       :format="theFormat"
                                       :duration="3"
                                       easing="Power1.easeOut"
                                    />
                                    </span>m<span class="m2">2</span></p>
                                    <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['9']}}</p>
                              </div>
                              <div class="key-number-item mb-30">
                                 <p class="key-number"><span class="number-to-animate" data-number="5000">
                                    <number
                                       animationPaused
                                       ref="number3"
                                       :from="0"
                                       :to="5000"
                                       :format="theFormat"
                                       :duration="3"
                                       easing="Power1.easeOut"
                                    />
                                    </span>m<span class="m2">2</span></p>
                                    <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['10']}}</p>
                              </div>
                           </div>
                        </div>

                        <div class="anim-slide-right">
                           <div>
                              <div class="key-number-item mb-30">
                                 <p class="key-number"><span class="number-to-animate">
                                    <number
                                       animationPaused
                                       ref="number4"
                                       :from="0"
                                       :to="12"
                                       :format="theFormat"
                                       :duration="3"
                                       easing="Power1.easeOut"
                                    />
                                    </span> ha</p>
                                    <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['11']}}</p>
                              </div>
                              <div class="key-number-item mb-30">
                                 <p class="key-number"><span class="number-to-animate">
                                    <number
                                       animationPaused
                                       ref="number5"
                                       :from="0"
                                       :to="68000"
                                       :format="theFormat"
                                       :duration="3"
                                       easing="Power1.easeOut"
                                    />
                                    </span>m<span class="m2">2</span></p>
                                    <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['12']}}</p>
                              </div>
                           </div>
                        </div>
                  </div>

                  <div class="footer-sct-img">
                     <figure><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/saintOuen-footer.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/saintOuen-footer-retina.jpg 2x"' alt=""></figure>
                  </div>
               </section>
         </div>
      </div>
    </div>
</template>

<script>

   // Observer.vue
   export default {
      name: 'SaintOuenMobile',
      props: {
         msg: String,
      },
      data(){
         return{
            scroll: null,
            increment: false,
            showZoom: false,
            AnimLoc: false,
            AnimtxtBlur: false,
         }
      },
      metaInfo() {
        return { 
            title: "Rhapsody - Saint-ouen énergie cinétique",
            meta: [
                { name: 'description', content:  'Suivre la ligne, RHAPSODY est accessible par la ligne 14, le RER C et en vélo. Ça bouge à saint-Ouen, venez découvrir le quartier autour de RHAPSODY'},
                { property: 'og:title', content: "Rhapsody - Saint-ouen énergie cinétique"},
                { property: 'og:site_name', content: 'Rhapsody'},
                { property: 'og:type', content: 'website'},    
            ]
        }
      },
      created () {
         window.addEventListener('scroll', this.handleScroll);
         // document.querySelector('.loader-container').classList.remove('loaded');
         // setTimeout(function () { 
         //    document.querySelector('.loader-container').classList.add('loaded');
         // }.bind(this), 1500)
      },
      destroyed () {
         window.removeEventListener('scroll', this.handleScroll);
      },
      components: {
         VideoSaintOuen: () => import('@/components/video/VideoSaintOuen.vue'),
         SaintOuen5: () => import('@/components/Page-saintOuen/SaintOuen5.vue'),
         SaintOuen6: () => import('@/components/Page-saintOuen/SaintOuen6.vue'),
         SaintOuen7: () => import('@/components/Page-saintOuen/SaintOuen7.vue'),
      },
      methods: {
         scrollToNext(e){
            e.preventDefault();
            var container = this.$el.querySelector(".saintOuen-2");
            container.scrollTop = container.scrollHeight;
            container.scrollIntoView({behavior: 'smooth'});
            this.beginAnimationZoom()
         },

         /*Fonction beginAnimationZoom*/
         beginAnimationZoom(){
            this.showZoom = true;
            setTimeout(() => {
               var showCircle = document.querySelector(".loc-bloc-circle")
               showCircle.style.opacity = "1"
               setTimeout(() => {
                    this.AnimLoc = true;
               }, 600);
            }, 500);
         },

         playAnimation() {
            this.$refs.number1.play()
            this.$refs.number2.play()
            this.$refs.number3.play()
            this.$refs.number4.play()
            this.$refs.number5.play()
         },

         theFormat(number) {
            return this.numberWithSpaces(number.toFixed(0));
         },

         numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         },
      },

      mounted() {
         document.body.classList.remove('removeHiddenOverflow');

         //Asynchrone check element
         setTimeout(() => {
            var parentEl = document.querySelectorAll(".sct-left .classParent");
            var listImg = document.querySelectorAll(".sct-right .map-harmony");            
           

            //Change sticky image
            const observer = new IntersectionObserver(([entry]) => {
               if (entry && entry.isIntersecting) {
                  for(let itemImg of listImg) {
                     if(itemImg.getAttribute('data-img') === entry.target.getAttribute('data-sect')) {
                        itemImg.classList.add('openMap');
                        
                        for(let nothis of listImg) {
                           if(nothis!==itemImg) nothis.classList.remove('openMap');
                        }
                     }
                  }               
               }
            });

            for(let item of parentEl) {
               observer.observe(item);
            }

            // Observe section Saint Ouen-05
            let options = {
               rootMargin: '0px',
               threshold: .995
            };

            var saintOuen5 = document.querySelector("#saintOuen-5");
            let scrollBox = saintOuen5.parentElement;
            let tmp = 0;
            let direct = "";
            var btNext = document.querySelector(".hooper-next");
            var btPrev = document.querySelector(".hooper-prev");
            const observer2 = new IntersectionObserver(([entry]) =>{
               if (entry && entry.isIntersecting) {
                  document.getElementById("saintOuen-5").addEventListener("wheel",(e)=>{
                     e.stopPropagation();
                     scrollBox.style.overflowY = "scroll";
                  });

                  //get Scroll value
                  scrollBox.addEventListener('scroll', ()=>{
                     if (tmp > scrollBox.scrollTop) {
                        direct = "up";
                     } else {
                        direct = "down";
                     }     
                     tmp = scrollBox.scrollTop;
                     
                     //Slide condition
                     if((direct==="up" && scrollBox.scrollTop===0)) {
                        setTimeout(() => {
                           btPrev.click()
                        }, 1000);
                        
                     }else if ((direct==="down" && scrollBox.scrollTop === (scrollBox.scrollHeight - scrollBox.clientHeight))){
                        setTimeout(() => {
                           btNext.click()
                        }, 500);
                     }
                  });
               }
            },options);

            observer2.observe(saintOuen5);
         }, 1000);

         //****************************** */
         // Increase number Mobile SAINTOUEN-7
         //****************************** */
         var circleBlocParent = document.querySelectorAll(".anim-number");

         const observer3 = new IntersectionObserver(([entry]) => {
               if( screen.width <= 1200 ) {
                  if (entry && entry.isIntersecting) {
                     setTimeout(() => {
                           this.playAnimation();
                     }, 200);          
                  }
               }
         });
         for(let item of circleBlocParent){
               observer3.observe(item);
         }

         setTimeout(() => {
            this.AnimtxtBlur = true;
         }, 2000);

         setTimeout(() => {
            document.querySelector("#first-page .bg-hero").play()
         }, 1000);

         /*Diffère tous les images*/
         setTimeout(function(){
            let loadImg = document.querySelectorAll('.loadTemp');
            for(let i = 0; i < loadImg.length; i++){
                  loadImg[i].setAttribute('srcset',loadImg[i].getAttribute('dta-srcset'));
                  loadImg[i].setAttribute('src',loadImg[i].getAttribute('dta-src'));
            }
         }.bind(this), 1200);

         this.beginAnimationZoom()
      }
   };

</script>